








































































@import '@design';

.location-card--wrap {
  max-width: 400px;
}
